import _ from 'lodash';
import moment from 'moment';
import mime from 'mime-types';
import NotificationMixin from '@/mixins/NotificationMixin';
import UtilsMixin from '@/mixins/UtilsMixin';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { DataTable } from '@/components/admin';

@Component({
  name: 'SurveyFormMixin'
})
export default class SurveyFormMixin extends Mixins(NotificationMixin, UtilsMixin) {
  selected: any = null;
  readonly $refs!: {
    addModal: BModal,
    editModal: BModal;
    deleteModal: BModal;
    duplicateModal: BModal;
    archiveModal: BModal,
    surveyTable: DataTable
  }

  handleAdd (data) {
    return this.$store.dispatch('survey/create', { data }).then(() => {
      this.$refs.addModal.hide();
      this.$refs.surveyTable.refresh();
    }).catch((err) => {
      const errMessage = err.message || 'Failed to create survey';
      this.notifyError(errMessage);
    });
  }

  handleEdit (data) {
    const { id } = this.selected;
    return this.$store.dispatch('survey/update', { id, data }).then(() => {
      this.$refs.editModal.hide();
      this.$refs.surveyTable.refresh();
    }).catch((err) => {
      const errMessage = err.message || 'Failed to update survey';
      this.notifyError(errMessage);
    });
  }

  handleDuplicate (data) {
    return this.$store.dispatch('survey/create', { data }).then(() => {
      this.$refs.duplicateModal.hide();
      this.$refs.surveyTable.refresh();
    }).catch((err) => {
      const errMessage = err.message || 'Failed to duplicate survey';
      this.notifyError(errMessage);
    });
  }

  handleDelete (evt) {
    const { id } = this.selected;
    return this.$store.dispatch('survey/delete', { id }).then(() => {
      this.$refs.deleteModal.hide();
      this.$refs.surveyTable.refresh();
    }).catch((err) => {
      const errMessage = err.message || 'Failed to delete survey';
      this.notifyError(errMessage);
    });
  }

  handleArchive (evt) {
    const { id } = this.selected;
    const data = {
      active: false
    };
    return this.$store.dispatch('survey/update', { id, data }).then(() => {
      this.$refs.archiveModal.hide();
      this.$refs.surveyTable.refresh();
    }).catch((err) => {
      const errMsg = err.message || 'Failed to archive survey.';
      this.notifyError(errMsg);
      this.$refs.archiveModal.hide();
    });
  }

  surveyPreviewLinkUrl (survey) {
    return `${process.env.VUE_APP_SURVEY_BASEURL}/${survey.client.slug}/${survey.slug}?preview=1`;
  }

  downloadSurveyResponses (survey) {
    const id = survey.id;
    const params = {
      dl: 1
    };
    this.notifyInfo('Downloading responses...');
    return this.$store.dispatch('survey/getResponses', { id, params }).then((res) => {
      const ext = mime.extension(res.type);
      const createdAt = moment(survey.createdAt).format('MMM YY');
      const fileName = `${survey.client.name}_${survey.teamName}_${survey.template.name}_Raw Data (${createdAt}).${ext}`;

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(res, fileName);
      } else {
        // For other browsers, create a link pointing to the ObjectURL containing the blob.
        const objectURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        document.body.appendChild(link); //  Need to do this for Firefox
        link.click();

        setTimeout(function () {
          document.body.removeChild(link); //  Need to do this for Firefox
          window.URL.revokeObjectURL(objectURL);
        }, 100);
      }

      this.notifySuccess('Responses downloaded');
    }).catch(() => {
      this.notifyError('Failed to download responses');
    });
  }

  copySurveyLink (survey) {
    const link = `${process.env.VUE_APP_SURVEY_BASEURL}/${survey.client.slug}/${survey.slug}`;
    this.copyToClipboard(link).then(() => {
      this.notifySuccess('Copied to clipboard');
    });
  }

  archiveSurvey (survey) {
    if (survey.status === 'completed') {
      this.selected = survey;
      this.$refs.archiveModal.show();
    }
  }
}
