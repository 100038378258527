import { mapGetters } from 'vuex';
import mime from 'mime-types';
import { Component, Mixins } from 'vue-property-decorator';
import NotificationMixin from './NotificationMixin';
import { BModal } from 'bootstrap-vue';

@Component({
  computed: {
    ...mapGetters('survey', ['getList', 'getCount', 'getParticipantList', 'getParticipantCount'])
  }
})
export default class SurveyMixin extends Mixins(NotificationMixin) {
    selected: any = null;
    readonly getParticipantList!: (id) => any[];
    readonly getParticipantCount!: (id) => number;
    readonly $refs!: {
        completionModal: BModal
    }

    get completionModalTitle () {
      const defaultTitle = 'Survey Completions';
      if (this.selected && this.selected.id) {
        return `${defaultTitle}: ${this.selected.client.name} • ${this.selected.teamName} • ${this.selected.name}`;
      }
      return defaultTitle;
    }

    // METHODS
    showCompletion (survey) {
      this.selected = Object.assign({}, survey);
      this.$refs.completionModal.show();
    }

    fetchCompletionData (params: Record<string, string> = {}) {
      if (this.selected && this.selected.id) {
        params.sort = params.sort || 'fullName';
        this.$store.dispatch('survey/participantList', { id: this.selected.id, params });
        this.$store.dispatch('survey/participantCount', { id: this.selected.id, params });
      }
    }

    getCompletionList () {
      return this.selected && this.selected.id ? this.getParticipantList(this.selected.id) : [];
    }

    getCompletionCount () {
      return this.selected && this.selected.id ? this.getParticipantCount(this.selected.id) : 0;
    }

    downloadCompletion () {
      const survey = this.selected;
      const params = {
        perPage: 1000
      };
      this.notifyInfo('Downloading survey completions...');
      this.$store.dispatch('survey/exportParticipantList', { id: survey.id, params }).then((res) => {
        const ext = mime.extension(res.type);
        const fileName = `${survey.client.name}_${survey.teamName}_${survey.template.name}_Completions.${ext}`;

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, fileName);
        } else {
          // For other browsers, create a link pointing to the ObjectURL containing the blob.
          const objectURL = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = fileName;
          document.body.appendChild(link); //  Need to do this for Firefox
          link.click();

          setTimeout(function () {
            document.body.removeChild(link); //  Need to do this for Firefox
            window.URL.revokeObjectURL(objectURL);
          }, 100);
        }
        this.notifySuccess('Survey completions downloaded');
      }).catch((err) => {
        const errMsg = err.message || 'Failed to download survey completions';
        this.notifyError(errMsg);
      });
    }
}
